/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable max-classes-per-file */
import Alpine from "alpinejs";
import { componentBase } from "../..";
import { AttributeSpecs, getAttributeInitState } from "./attribute";

export * from "./attribute";
export type AttributeChangeEvent<T> = CustomEvent<{ oldValue: T; newValue: T }>;
export type ChangeEvent<T> = CustomEvent<{ name: string; oldValue: T; newValue: T }>;
export type TargetedEvent<T = object> = CustomEvent<string | Partial<{ id: string } & T>>;

/**
 * Grabs a filtered set of attributes from the "template" node (which would be the child
 * nested inside a <template> tag, not the template element itself). This is used as a way
 * to add default attributes for a web component just by adding some markup to the template.
 */
export function getDefaultAttrs(template: Element): Record<string, string> {
  const attrs: Record<string, string> = {};
  let i = template.attributes.length;
  while (i--) {
    const { name, value } = template.attributes[i];
    if (name !== "id") attrs[name] = value;
  }
  return attrs;
}

/**
 * A tag function that makes it easy to write the template markup for a web component
 * inline with the rest of the component class. It will automatically add a surrounding
 * <template> tag to the markup contained within and return a DocumentFragment with the
 * single element contained within the raw markup.
 *
 * The template must contain a single parent element since that element will be replaced
 * with the actual web component once mounted onto the DOM.
 *
 * No two slots can share the same name, so if a slot needs to be displayed twice, use
 * either an attribute to the component or add a second slot with a unique name.
 */
export function html(raw: TemplateStringsArray, ...substitutions: unknown[]): Element {
  const markup = String.raw({ raw }, ...substitutions);
  const rendered = document.createElement("div");
  rendered.innerHTML = `<template>${markup}</template>`;
  const template = rendered.querySelector("template");
  /* istanbul ignore if */
  if (template === null) {
    throw new Error("Unreachable code");
  }
  if (
    template.content.firstElementChild === null ||
    template.content.children.length !== 1
  ) {
    throw new Error("Component template must contain a single parent node");
  }
  /* test for duplicate named slots */
  const slotNames = new Set();
  const slots = template.content.querySelectorAll("slot");
  const slotCount = slots.length;
  for (let i = 0; i < slotCount; i++) {
    const slot = slots.item(i);
    const thisSlotName = slot.getAttribute("name");
    if (slotNames.has(thisSlotName)) {
      throw new Error(`Slot with name '${thisSlotName}' is used more than once.`);
    }
    slotNames.add(thisSlotName);
  }
  return template.content.firstElementChild;
}

export function isTemplate(node: Node): node is HTMLTemplateElement {
  return node.nodeType === Node.ELEMENT_NODE && (node as Element).tagName === "TEMPLATE";
}

function getTagConstructor<K extends keyof HTMLElementTagNameMap>(
  tagName: K
): { new (): HTMLElementTagNameMap[K] } {
  return document.createElement(tagName).constructor as {
    new (): HTMLElementTagNameMap[K];
  };
}

function getTemplateConstructor<T extends HTMLElement>(
  template: Element | undefined
): { new (): T } | undefined {
  return template?.constructor as { new (): T };
}

/**
 * An abstract class for light DOM web components that are integrated with Alpine.js.
 *
 * @param tagName The tag name to use for the custom element, which must at least contain a
 * hyphen. If not provided it can be supplied later in a call to `customElements.define` or
 * the `define` static method.
 * @param boundAttributes A mapping of attribute names to a codec (called an AttributeSpec)
 * that knows how to create a two-way binding with a reactive state object, as well as
 * provide an optional initial value to use.
 * @param templateHtml An optional string containing markup to use the template for the
 * element. This template may contain one or more <slot> tags which will be removed upon
 * mounting into the DOM and replaced with any children that reference those slots using
 * the `slot` attribute. Unnamed slots in the template will collect all remaining children.
 * If a template is not provided, any children of the custom element are left untouched.
 * @param extend An optional string identifying the name of the tag to use as a base element
 * (passed as-is to the third argument for customElements.define). With this, the custom
 * component can extend the behavior of any HTML element and will automatically look up the
 * correct base class to extend for that tag. For example, extending the "div" tag will
 * cause the base class to be HTMLDivElement instead of HTMLElement (the default). Extended
 * elements are initialized using the original tag name and the "is" attribute, like so:
 * `<div is="x-special-div"></div>`
 * @returns A class extending HTMLElement that can be used as a custom component
 * constructor with `customElements.define`. For convenience, this class has a `define()`
 * static method that can be used to register the component as well.
 */
export function AlpineWebComponent<
  State extends object,
  Attrs extends Partial<State> = object,
  // NOTE: `i` is not the actual default value, but it maps to `HTMLElement` for sake
  // of typechecking; `HTMLElement` is the base element used when extend is left undefined.
  BaseElementTagName extends keyof HTMLElementTagNameMap = "i"
>(
  tagName?: string,
  boundAttributes: AttributeSpecs<Attrs> = {},
  template: Element | undefined = undefined,
  extend: BaseElementTagName | undefined = undefined
) {
  type BaseElement = HTMLElementTagNameMap[BaseElementTagName];
  type BaseElementCtor = { new (): BaseElement };

  if (tagName && tagName.indexOf("-") === -1) {
    throw new Error(
      `${tagName} is not a valid custom element name (https://html.spec.whatwg.org/#valid-custom-element-name)`
    );
  }

  const defaultAttrs = template ? getDefaultAttrs(template) : {};
  // Perform type erasure here to allow extending a class with static members. Typescript
  // can't figure out how to extend (HTMLElement | HTMLDivElement | ...), but we can cheat
  // here since HTMLElement is the common ancestor and contains all of the common
  // properties that are useful here. Despite the fact that document.createElement will
  // return the correct type, it is "erased" here and coerced back to HTMLElement. After the
  // class is defined, another type coersion happens to unify the intended base class with
  // the constructed class.
  const BaseElementClass: { new (): HTMLElement } = extend
    ? getTemplateConstructor<BaseElement>(template) || getTagConstructor(extend)
    : (HTMLElement as unknown as BaseElementCtor);

  class ComponentClass extends BaseElementClass {
    static define(overrideName?: string) {
      const name = overrideName || tagName;
      if (!name || name.indexOf("-") === -1) {
        throw new Error(`${name} is not a valid custom element name`);
      }
      customElements.define(
        name,
        this as unknown as CustomElementConstructor,
        extend ? { extends: extend } : {}
      );
    }

    static get observedAttributes() {
      return Object.keys(boundAttributes);
    }

    /* eslint-disable */
    constructor(...args: any[]) {
      // This may be a Typescript bug and needs more research.
      // @ts-ignore
      super(...args);
    }
    /* eslint-enable */

    $dispatch = <T>(name: string, detail = {} as T) => {
      this.dispatchEvent(
        new CustomEvent(name, { detail, bubbles: true, composed: true, cancelable: true })
      );
    };

    $removeEffect?: ReturnType<typeof Alpine.effect>;

    $slots: Record<string, Node[]> = {};

    readonly state = Alpine.reactive({
      ...componentBase,
      ...getAttributeInitState(boundAttributes),
      ...(this.data() as State),
    });

    $bindAttributes() {
      this.$removeEffect = Alpine.effect(() => {
        const boundNames = Object.keys(boundAttributes);
        let j = boundNames.length;
        while (j--) {
          const name = boundNames[j];
          const k = name as keyof this["state"];
          this.$marshal(boundNames[j], this.state[k]);
        }
      });
    }

    $collectChildren() {
      this.$slots[""] = [];
      let i = this.childNodes.length;
      while (i--) {
        const child = this.childNodes[i];
        const el = child as Element;
        const slot = el.getAttribute && el.getAttribute("slot");
        if (slot) {
          if (this.$slots[slot] === undefined) {
            this.$slots[slot] = [];
          }
          this.$slots[slot].unshift(child);
        } else {
          this.$slots[""].unshift(child);
        }
      }
    }

    $isTargetedByEvent(ev: TargetedEvent<unknown>): boolean {
      if (!ev.detail) return true;
      const targetId = typeof ev.detail === "string" ? ev.detail : ev.detail.id;
      return targetId === undefined || targetId === this.id;
    }

    $marshal(name: string, value: unknown) {
      const k = name as keyof State;
      const o = boundAttributes[k];
      if (!o) return;
      o.marshal(this, name, value as Attrs[typeof k]);
    }

    $renderSlots(root: DocumentFragment | HTMLElement = this) {
      root.querySelectorAll("template").forEach((t) => this.$renderSlots(t.content));
      const slots = Array.from(root.querySelectorAll("slot"));

      for (let i = 0, slotCount = slots.length; i < slotCount; ++i) {
        const slot = slots[i];
        const slotName = slot.getAttribute("name");
        const children = this.$slots[slotName || ""];

        // Following is unoptimized code that benchmarks much slower:
        // const nodes = children
        //   ? children.map((c) => c.cloneNode(true))
        //   : Array.from(slot.childNodes);
        let nodes: Node[];
        if (children) {
          const numChildren = children.length;
          nodes = new Array<Node>(numChildren);
          for (let j = 0; j < numChildren; j++) {
            const child = children[j];
            nodes[j] = isTemplate(child) ? child.content.cloneNode(true) : child;
          }
        } else {
          nodes = Array.from(slot.childNodes);
        }

        slot.replaceChildren();
        if (slotName === null) {
          slot.after(...nodes);
          slot.remove();
        } else {
          slot.replaceWith(...nodes);
        }
      }
    }

    $renderTemplate() {
      if (!template) return;
      this.innerHTML = "";
      const l = template.childNodes.length;
      const nodes = new Array<Node>(l);
      for (let i = 0; i < l; i++) {
        nodes[i] = template.childNodes[i].cloneNode(true);
      }
      this.append(...nodes);
    }

    $setDefaultAttributes() {
      const defaultNames = Object.keys(defaultAttrs);
      let i = defaultNames.length;
      while (i--) {
        const name = defaultNames[i].replace(/^@/, "x-on:");
        const value = defaultAttrs[defaultNames[i]];
        if (!this.hasAttribute(name)) this.setAttribute(name, value);
      }
    }

    attributeChangedCallback(
      name: string,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _oldValue: string | null,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _newValue: string | null
    ): void {
      const k = name as keyof this["state"];
      const o = boundAttributes[k as keyof State];
      if (o) this.state[k] = o.unmarshal(this, name) as unknown as this["state"][typeof k];
    }

    connectedCallback() {
      Alpine.mutateDom(() => {
        this.$bindAttributes();
        // NOTE: Most of the time the component will not have an existing x-data attribute.
        // Some libraries like HTMX do element swapping in a web browser and will attempt to
        // preserve attributes during the swap operation. This unfortunately can leave the
        // Alpine initialization incomplete so this function can't simply exit early in that
        // case. This behavior is difficult to reproduce in JSDOM and ignored for coverage.
        /* istanbul ignore else */
        if (!this.hasAttribute("x-data")) {
          this.$collectChildren();
          this.$renderTemplate();
          this.$renderSlots();
          this.$setDefaultAttributes();
        }
        this.setAttribute("x-data", "$el.state");
      });
    }

    disconnectedCallback() {
      this.removeAttribute("x-data");
      /* istanbul ignore else */
      if (this.$removeEffect) {
        this.$removeEffect();
        this.$removeEffect = undefined;
      }
    }

    /**
     * This method defines the initial state object that will be available to bind with
     * Alpine attributes. This state is stored directly on the web component and accessible
     * as `this.state`.
     *
     * Non-stateful components do not need to override this function at all.
     *
     * @returns A new initial state object to use for the component.
     */
    // eslint-disable-next-line class-methods-use-this
    data(): Omit<State, keyof Attrs> {
      return {} as unknown as Omit<State, keyof Attrs>;
    }
  }

  // Restore the type information that was erased earlier.
  return ComponentClass as typeof ComponentClass & BaseElementCtor;
}

export default AlpineWebComponent;
