export type ComponentBase = {
  $dispatch: (eventName: string, data?: unknown) => void;
  $el: HTMLElement;
  $id: (ref: string, suffix?: string) => string;
  $refs: Record<string, Element>;
  $store: Record<string, unknown>;
  $watch: <T>(property: string, cb: (value: T, oldValue: T) => void) => void;
  destroy?: () => void;
  init?: () => void;
};

export type AlpineClass = Record<string, boolean> | string;
export type Component<Props> = (id: unknown) => ComponentBase & Props;
export type HydratedComponent<Args, Props> = (args: Args) => ComponentBase & Props;

export const componentBase = {} as ComponentBase;
