/**
 * A data structure that lets an AlpineWebComponent instance know how to create a two-way
 * binding between Alpine state and a DOM attribute.
 */
export interface AttributeSpec<T> {
  /**
   * The initial value for an attribute; this is applied any time the component is
   * connected to the DOM and will **not** override any attributes set by the host
   * document.
   */
  initial: T | undefined;
  marshal(el: HTMLElement, name: string, value?: T): void;
  unmarshal(el: HTMLElement, name: string): T;
}

/**
 * A mapping from component state properties to an AttributeSpec that defines how to create
 * the two-way binding to a DOM attribute with the same name.
 */
export type AttributeSpecs<State extends object> = {
  [k in keyof State]?: AttributeSpec<State[k]>;
};

export type Attributes<Specs extends AttributeSpecs<object>> = {
  [k in keyof Specs]: Specs[k] extends AttributeSpec<infer T> ? T : never;
};

export function getAttr<T>(
  el: HTMLElement,
  name: string,
  cb: (v: string) => T
): T | undefined {
  const attrValue = el.getAttribute(name);
  return attrValue === null ? undefined : cb(attrValue);
}

export function hasAttr(el: HTMLElement, name: string) {
  return el.hasAttribute(name);
}

export function setAttr(el: HTMLElement, name: string, cb: () => string | undefined): void {
  const value = cb();
  if (value === undefined) {
    el.removeAttribute(name);
  } else {
    el.setAttribute(name, value);
  }
}

export function getAttributeInitState<State extends object>(
  attrs: AttributeSpecs<State>
): Partial<State> {
  const init: Partial<State> = {};
  const names = Object.keys(attrs) as Array<keyof State>;
  let i = names.length;
  while (i--) {
    const k = names[i];
    /* istanbul ignore next */
    init[k] = attrs[k]?.initial;
  }
  return init;
}

export const Attribute = {
  Boolean: (initial?: boolean): AttributeSpec<boolean> => ({
    initial,
    marshal: (el, name, v) => setAttr(el, name, () => (v ? "" : undefined)),
    unmarshal: hasAttr,
  }),

  Integer: (initial?: number): AttributeSpec<number | undefined> => ({
    initial,
    marshal: (el, name, v) => setAttr(el, name, () => v?.toString()),
    unmarshal: (el, name) =>
      getAttr(el, name, (v) => {
        const num = parseInt(v, 10);
        if (Number.isNaN(num)) return undefined;
        return num;
      }),
  }),

  ISODate: (initial?: Date): AttributeSpec<Date | undefined> => ({
    initial,
    marshal: (el, name, v) => setAttr(el, name, () => v?.toISOString()),
    unmarshal: (el, name) =>
      getAttr(el, name, (v) => {
        const d = new Date(v);
        if (Number.isNaN(d.valueOf())) return undefined;
        return d;
      }),
  }),

  JSON<T>(initial?: T): AttributeSpec<T | undefined> {
    const unmarshalFunction = (v: string) => {
      const jstr = v !== "undefined" ? v : '""';
      return JSON.parse(decodeURIComponent(jstr)) as T;
    };
    return {
      initial,
      marshal: (el, name, v) =>
        setAttr(el, name, () => encodeURIComponent(JSON.stringify(v))),
      unmarshal: (el, name) => getAttr(el, name, (v) => unmarshalFunction(v)),
    };
  },

  String: (initial?: string): AttributeSpec<string | undefined> => ({
    initial,
    marshal: (el, name, v) => setAttr(el, name, () => v),
    unmarshal: (el, name) => getAttr(el, name, (v) => v),
  }),
};
